import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "firmware"
};
const _hoisted_2 = {
  class: "firmware-header"
};
const _hoisted_3 = {
  class: "select-box"
};
const _hoisted_4 = {
  class: "firmware-table"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_my_table = _resolveComponent("my-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h1", null, _toDisplayString(_ctx.$t('message.firmware')), 1), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_select, {
    modelValue: $setup.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.value = $event),
    filterable: "",
    placeholder: _ctx.$t('message.pleaseSelect'),
    "popper-class": "firmware-select",
    "no-data-text": _ctx.$t('message.noData'),
    "no-match-text": _ctx.$t('message.noMatch'),
    onChange: $setup.hanldeSelectChange,
    clearable: ""
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item.value,
        label: item.label,
        value: item.value
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "placeholder", "no-data-text", "no-match-text", "onChange"])])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_my_table, {
    options: $setup.tableList,
    isProduct: true
  }, null, 8, ["options"])])]);
}