import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import MyTable from '@/components/MyTable.vue';
import { ElLoading } from 'element-plus';
import { isRepeatObjInArr, alertMessage } from '@/assets/js/tool.js';
import { getProductList } from '@/service/index.js';
import { useI18n } from 'vue-i18n';
export default {
  components: {
    MyTable
  },
  setup() {
    //data
    const {
      t
    } = useI18n();
    const value = ref('');
    const options = ref([]);
    const tableList = ref([]);
    const oldList = ref([]);
    const loading = ElLoading.service({
      lock: true,
      background: 'rgba(0, 0, 0, 0)'
    });

    //获取固件列表
    getProductList().then(res => {
      loading.close();
      if (res.status === 0) {
        tableList.value = res.data;
        oldList.value = res.data;
        options.value = getSelectList(res.data);
      } else {
        let text = res ? res.msg || t('failed.getData') : t('failed.getData');
        alertMessage('error', text);
      }
    });

    //function
    /**获取下拉列表*/
    function getSelectList(list) {
      let searchList = [];
      list.forEach(item => {
        let typeCode = item.productModel ? item.productModel.typeCode || '' : '';
        let data = typeCode ? {
          value: typeCode,
          label: typeCode
        } : {};
        let repeat = isRepeatObjInArr(searchList, data);
        if (typeCode && !repeat) {
          searchList.push(data);
        }
      });
      return searchList;
    }

    /**下拉框选项发生变化*/
    function hanldeSelectChange(val) {
      let newList = [];
      if (!val) {
        tableList.value = oldList.value;
      } else {
        oldList.value.forEach(item => {
          if (item.productModel.typeCode === val) {
            newList.push(item);
          }
        });
        tableList.value = newList;
      }
    }
    return {
      value,
      options,
      tableList,
      loading,
      hanldeSelectChange
    };
  }
};